import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
//import { isNumber, toInteger, padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
        const dateParts: string[] = value.trim().split('/');
        if (dateParts.length === 1 && Number.isInteger(+dateParts[0])) {
            return { day: parseInt(dateParts[0]), month: 0, year: 0 }; // Default values
        } else if (dateParts.length === 2 && Number.isInteger(+dateParts[0]) && Number.isInteger(+dateParts[1])) {
            return { day: parseInt(dateParts[0]), month: parseInt(dateParts[1]), year: 0 }; // Default year
        } else if (dateParts.length === 3 && Number.isInteger(+dateParts[0]) && Number.isInteger(+dateParts[1]) && Number.isInteger(+dateParts[2])) {
            return { day: parseInt(dateParts[0]), month: parseInt(dateParts[1]), year: parseInt(dateParts[2]) };
        }
    }
    return null; // Return null if parsing fails
}



  format(date: NgbDateStruct): string {
    return date ?
        `${Number.isInteger(date.day) ? this.padNumber(date.day) : ''}/${Number.isInteger(date.month) ? this.padNumber(date.month) : ''}/${date.year}` :
        '';
  }

  padNumber(value: number) {
    if (Number.isInteger(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
}